@import '../../../scss/theme-bootstrap';

.header-gnav-cart {
  &__overlay {
    padding: 0;
  }
  &__overlay.visible {
    @media #{$cr19-large-up} {
      width: 534px; // @todo make this a var to share with search and sections
    }
  }
  &__content-wrapper {
    &.active {
      margin-top: 0;
    }
  }
  .cart-confirm-wrapper {
    @include text-body-text--no-xl;
    background: $color-white;
    border: none;
    padding: 0;
    position: relative;
    font-family: $base-bold-font-family;
    &__top-section {
      @include swap_direction(padding, 15px 0 0 30px);
    }
    &__header {
      font-size: 22px;
      font-weight: 900;
      display: inline-block;
      font-family: $base-bold-font-family;
    }
    &__tabset {
      border-bottom: 1px solid $color-black;
    }
    &__tab {
      font-family: $base-bold-font-family;
      width: 50%;
      text-align: center;
      display: inline-block;
      font-weight: 900;
      line-height: 40px;
      position: relative;
      cursor: pointer;
      &.selected {
        border-bottom: 1px solid $color-black;
      }
      sup {
        font-size: 12px;
        height: 15px;
        line-height: 15px;
        text-align: center;
        width: 15px;
        display: inline-block;
        margin-#{$ldirection}: -4px;
        vertical-align: super;
        &.highlighted {
          font-size: 10px;
          background-color: $color-black;
          border-radius: 50%;
          color: $color-white;
          margin-#{$ldirection}: 3px;
        }
      }
    }
    &__btn-edit {
      margin-#{$ldirection}: 25px;
      font-weight: 900;
      font-family: $base-bold-font-family;
      border-bottom: 1px solid $color-black;
    }
    &__content {
      &--offers {
        background-color: $color-bg-grey;
      }
    }
    &__offer-container {
      background-color: $color-bg-grey;
      padding: 0 14px;
      &--last {
        border-top: 1px solid $color-cl-grey;
      }
    }
    &__offer-section-label {
      font-size: 15px;
      font-weight: 900;
      padding: 20px 0 14px;
      &--normal {
        font-weight: normal;
      }
    }
    &__offer {
      padding-bottom: 14px;
      display: flex;
      flex-direction: row;
      .hleft {
        flex: 1;
        display: inline-block;
        vertical-align: top;
        margin-#{$rdirection}: 10px;
      }
      .hright {
        display: inline-block;
        flex: 2;
        vertical-align: top;
      }
    }
    &__offer-node {
      width: 100%;
    }
    &__offer-image {
      text-align: #{$rdirection};
      border: 1px solid $color-grey-light;
      width: 185px;
      height: 85px;
      overflow: hidden;
      img {
        max-width: 183px;
        max-height: 83px;
      }
    }
    &__offer-description {
      font-size: 14px;
      font-weight: 900;
      color: $color-darker-grey-2;
      margin-bottom: 5px;
    }
    &__offer-cta {
      font-size: 14px;
      font-weight: 900;
      color: $color-black;
      margin-bottom: 5px;
      font-family: $base-bold-font-family;
      display: inline-block;
      border-bottom: 1px solid $color-black;
    }
    &__offer-threshold {
      font-size: 14px;
      font-weight: 900;
      color: $color-cl-orange-yellow;
      margin-bottom: 5px;
      font-style: italic;
      &::before {
        content: '!';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $color-cl-orange-yellow;
        color: $color-white;
        margin-#{$rdirection}: 3px;
        display: inline-block;
        font-style: normal;
        text-align: center;
        font-size: 10px;
        font-weight: normal;
        line-height: 12px;
        vertical-align: text-top;
        font-family: $base-bold-font-family;
      }
    }
    &__offer-not-available {
      font-size: 16px;
      color: $color-darker-grey-2;
      padding: 20px 14px;
      font-family: $base-bold-font-family;
      font-style: italic;
      text-align: center;
    }
    &__bottom-section {
      // added as a span for 2019 Redesign
      background-color: $color-white;
      display: block;
      margin-bottom: 60px;
      width: 100%;
      border-top: none;
      @media only screen and (max-width: 900px) {
        position: sticky;
        bottom: 0;
        z-index: 1;
        border-top: solid 1px $color-light-gray;
        border-bottom: solid 10px $color-white;
      }
    }
    a.close {
      background: none;
      width: 17px;
      height: 17px;
    }
    h2 {
      @include text-title--small;
      color: $cr19-text;
    }
    h2.cart-confirm-heading {
      background: transparent url('/media/images/global/nav/top/calendar-icon--dark.png') no-repeat
        scroll 0 5px;
      color: $cr19-text;
      font-family: $base-font-family-roman;
      font-size: 13px;
      letter-spacing: 0.03em;
      line-height: get-line-height(13px, 20px);
      margin: 0;
      padding-#{$ldirection}: 40px;
      p {
        line-height: get-line-height(13px, 20px);
      }
    }
    .cart-overlay-products {
      display: block;
      height: 400px;
      max-height: 400px;
      border-bottom: none;
      padding-bottom: 100px;
    }
    .cart-overlay-products-heading {
      @include text-body-text--no-xl;
      border-bottom: none;
      text-transform: uppercase;
      padding-bottom: 2px;
      margin-top: 20px;
    }
    .cart-overlay-products-heading div {
      margin: 0;
      &.hright {
        padding-#{$rdirection}: 10px;
      }
      &.hleft {
        float: #{$ldirection};
      }
    }
    .cart-overlay-products-heading .hcenter {
      width: 260px;
    }
    .last-item-added {
      background-color: $cr19-bg-light;
    }
    .prod {
      gap: 10px;
      position: relative;
      align-items: flex-start;
      border-bottom: none;
      display: flex;
      flex-direction: row;
      min-height: 90px; // keep from jumping while images load
      padding: 12px 0;
      margin: 0;
      width: 100%;
      &.customkit {
        border-bottom: solid 1px $cr19-border-light;
        align-items: start;
      }
    }
    .cart-product-img {
      @include swap_direction(margin, 0 0 0 12px);
      color: $cr19-text;
      float: none;
      text-align: #{$ldirection};
      max-width: 120px;
      &.custom-kit {
        margin-top: 8px;
      }
      img {
        background-color: $cr19-bg-light;
        font-size: 0;
        height: auto;
        max-width: 80px;
        width: 80px;
      }
    }
    // shady
    .shade-info {
      align-items: center;
      display: flex;
      margin: 0;
      height: auto;
      padding-bottom: 6px;
      &.hidden {
        display: none;
      }
    }
    .shade-thumb {
      @include swap_direction(margin, 0 6px 0 0);
      float: #{$ldirection};
      width: 20px;
      height: 20px;
      .shade-thumb_2 {
        width: 50%;
        height: 100%;
        float: #{$ldirection};
      }
      .shade-thumb_3 {
        width: 33%;
        height: 100%;
        float: #{$ldirection};
      }
      .shade-thumb_4 {
        width: 50%;
        height: 50%;
        float: #{$ldirection};
      }
    }
    .shadename {
      width: auto;
      font-size: 11px;
      line-height: 11px;
      height: auto;
      white-space: normal;
      text-align: #{$ldirection};
      display: inline-block;
      font-family: $base-font-family-roman;
    }
    .prod-info {
      margin-#{$rdirection}: 30px;
      padding-top: 10px;
      width: 100%;
      display: flex;
      gap: 20px;
      .sample-color {
        color: $cr19-text;
      }
      .disclaimer {
        font: normal 400 15px/1.4 $base-font-family-roman;
        letter-spacing: 0.02em;
        @media #{$medium-up} {
          font-size: 17px;
        }
      }
      .category-heading {
        font: normal bold 18px/1.4 $base-font-family-roman;
        letter-spacing: 0.02em;
      }
      .product-name {
        font: normal 400 15px/1.4 $base-font-family-roman;
        margin-bottom: 20px;
      }
      .price {
        white-space: nowrap;
        flex: 1;
      }
      &__info-remove {
        background: url('/media/images/icons/plus-icon.png') no-repeat 0 0 transparent;
        background-size: 16px;
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        transform: rotate(45deg);
        margin: 0;
        #{$rdirection}: 13px;
        top: 24px;
      }
      &__size {
        @include text-body-text--no-xl;
        color: $cr19-text-grey;
        clear: both;
      }
    }
    .cart-product-name {
      @include text-body-text--no-xl;
      color: $cr19-text;
      display: inline;
      float: #{$ldirection};
      margin: 0;
      max-width: 80%;
      padding: 0;
      width: auto;
      flex: 2;
      .custom-heading {
        font: normal 400 28px/1.4 $base-font-family-roman;
      }
      a {
        @include text-body-text--no-xl;
        @include text-link;
        @include text-link--style-2;
      }
    }
    .custom-kit {
      &__price-details {
        display: flex;
        width: 100%;
        flex: 1;
      }
      &__total-price {
        &--price-through {
          color: $cr19-text-grey;
          text-decoration: line-through;
        }
      }
      &__discount-price {
        width: 60%;
      }
    }
    .prod-details {
      margin: 0;
      flex: 1;
      width: 100%;
      display: flex;
      float: #{$rdirection};
      &.hidden {
        display: none;
      }
    }
    .right-copy.qty-price {
      @include text-body-text--no-xl;
      margin-top: 0;
      display: flex;
      justify-content: space-between;
      gap: 15px;
      width: 100%;
    }
    .qty {
      color: $cr19-text;
      margin-#{$rdirection}: 0;
      padding-#{$rdirection}: 0;
    }
    .left-copy {
      @include text-body-text--no-xl;
      color: $cr19-text;
    }
    .right-copy {
      float: #{$rdirection};
      text-align: #{$rdirection};
    }
    .items,
    .subtotal,
    .buttons,
    .total {
      @include text-body-text--no-xl;
      padding: 0;
    }
    .items {
      float: #{$ldirection};
      font-family: $base-bolder-font-family;
      padding: 14px;
    }
    .subtotal {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: space-between;
      font-family: $base-bolder-font-family;
      padding: 14px;
      width: 100%;
      display: flex;
      .left-copy {
        float: #{$ldirection};
        text-align: #{$ldirection};
        font-family: $base-bolder-font-family;
        margin: 0;
        flex: 1;
      }
      .right-copy {
        float: #{$rdirection};
        &__subtotal {
          float: none;
          flex: 1;
          text-align: #{$rdirection};
        }
      }
    }
    .total_div {
      width: 100%;
      padding-bottom: 10px;
      .last {
        color: $cr19-text;
        float: #{$rdirection};
        width: 130px;
        font-family: $base-bold-font-family;
        text-transform: uppercase;
      }
      .total {
        width: 90px;
        float: #{$rdirection};
        text-align: #{$rdirection};
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      border-top: none;
      .right {
        text-align: #{$rdirection};
      }
      .left-copy {
        /* add margin to emulate desktop button space */
        @media screen and (max-width: 767px) {
          margin-#{$rdirection}: 0.25em;
        }
        width: 50%;
        a.button.button-secondary {
          @include elc-button;
          @include elc-button--light;
          padding: 0;
          margin: 0;
          border-color: 2px solid $cr19-border-light;
          font-size: 17px;
          @media #{$cr19-large-up} {
            width: calc(100% - 5px);
          }
        }
      }
      .right-copy {
        width: 50%;
        /* add margin to emulate desktop button space */
        @media screen and (max-width: 767px) {
          margin-#{$ldirection}: 0.25em;
        }
        a.btn-primary {
          @include elc-button;
          @include elc-button--dark;
          padding: 0;
          margin: 0;
          @media #{$cr19-large-up} {
            width: calc(100% - 5px);
          }
        }
      }
    }
  }
}
